<script>
	export default {
		name:"OnlineTEMPLATE1",
		components: {
			'mt-header': app.Mint.Header,
			'mt-button': app.Mint.Button,
		},
		props:[
			'remark',
			'items',
			'money',
			'imgTrue',
			'money_other',
			'getLineCanal',
		],
	}
</script>
<template>
	<div id="recharge" ref="recharge">
		<mt-header :title="getWord(['online2', 'payment'])" style="height:0.88rem;font-size: 0.35rem;">
	        <router-link to="/money" slot="left">
	            <mt-button icon="back"></mt-button>
	        </router-link>
	    </mt-header>
	    <div class="content">
	        <ul>
	            <li v-for="item in items" @click="money(item)" :class="{active:item.active}">{{ currencySymbol() + item.value}}</li>
	        </ul>

	        <input @blur="_blured($event)" class="input_money" oninput = "value=value.replace(/[^\d]/g,'')" type="text" :placeholder="getWord(['fill', 'other' , 'amount_of_betting'])" v-model="money_other"/>
	        
	        <h4>应付: <span v-if="money_other">{{money_other}}{{ currencySymbol() }}</span></h4>
	        <mt-button :class="['submit']" @click="getLineCanal" size="large">{{ getWord(['immediately', 'recharge']) }}</mt-button>

	        <div class="tip">
	            <h4>{{ getWord('tips') }}:</h4>
	            <div v-html="remark"></div>
	        </div>

	        <img v-if="imgTrue.tf && listType != 3" :src="imgTrue.url" class="width:6rem;height:6rem;border:1px solid #CCC;margin:0.3rem 0 0 0.65rem;" />
	        <qriously v-else-if="imgTrue.tf && listType == 3" :value="imgTrue.url" :size='size' style='text-align:center;margin:0;padding:0;'/>
	        <p v-if="imgTrue.tf" style="text-align:center;margin:0 0 0 -15px;padding:0;">{{ getWord('scan_qrcode') }}</p>
	    </div>
	</div>
</template>
<style lang="scss" scoped type="text/css">
.content {
    padding:0 .55rem;

    button.submit {
        background-color: #4796FC;
        color: #ffffff;
        margin: .25rem 0;
    }

    > h4 {
        font-size: .3rem;
        font-weight: normal;
    }

    .input_money {
        width: 100%;
        margin: .25rem 0;
        padding: 0 0.25rem;
        height: 0.7rem;
        box-sizing: border-box;
    }

    ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: .25rem;

        li {
            font-size: 0.3rem;
            border: 1px solid #999999;
            border-radius: 3px;
            padding: .1rem .2rem;
            width: 1.2rem;
            text-align: center;
            margin: .125rem 0;

            &.active {
                background-color: #4796FC;
                color: #ffffff;
                border-color: #4796FC;
            }
        }
    }

    .tip {
        color: #999999;

        h4,
        p {
            font-size: .24rem;
        }
    }
}	
</style>